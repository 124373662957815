require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
var $ = require("jquery")


window.jQuery = $;
window.$ = $;

require("@nathanvda/cocoon")
require("trix")
require("autonumeric")
require("@rails/actiontext")
require("@yaireo/tagify")


import '../stylesheets/v2/theme/dashforge.scss'
import '../stylesheets/v2/theme/custom-style/custom-style.scss'
import "@yaireo/tagify/src/tagify.scss";